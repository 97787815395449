.toast-container-root {
    width: 526px;

    .toast-simple {
        padding: 0;
        width: 536px;
        border-radius: 8px 0px 0px 8px;
    }

    .toast-body {
    }
}
