.app-logo {
    &.large {
        width: 250px;
        height: 250px;
    }
    &.clickable {
        cursor: pointer;
    }

    &.logo-navbar {
        width: 185px;
    }
}
