@import '../../../../Themes/theme.scss';

.confirm-dialog-content {
    margin: 16px 24px 0px 24px; //* TOP LEFT BOTTOM RIGHT
    color: $dialogContentTextColor;
    font-family: $fontFamily;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
}
