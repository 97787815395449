@mixin withLinkButtonStyle {
    text-decoration: none;
}

@mixin withInputContainerStyle($height, $flexAlignItems) {
    .Input-container {
        height: $height;

        .Input-formControl {
            height: inherit;

            .Input-inputBase {
                height: inherit;
                align-items: $flexAlignItems;
                overflow-y: auto;
            }
        }
    }
}

//*in single line
@mixin withSingleline {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
