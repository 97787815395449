//* COLORS
$white: #ffffff;

$black: #000000;
$black_e3: #e3e3e3;
$black_e5: #e5e5e5;
$black_d4: #d4d4d4;
$black_ac: #acacac;
$black_9b: #9b9b9b;
$black_1a: #1a1a1a;
$black_cd: #cdcdcd;
$black_97: #979797;
$black_be: #bebebe;
$black_ed: #ededed;
$black_de: #dedede;
$black_cc: #cccccc;

$outerSpace: #29322f;
$dusk: #3b395f;

$heavyMetal: #2d322e;
$martinique: #2b284e;
$darkRed: #d92b1f;
$darkGrey: #29322f;
$lightGreen: #22b00b;

$vividGreen: #31d416;
$christi: #28b711;
$athensGrey: #edeef2;
$abbey: #4c5155;

$athensGray: #f1f2f6;
$outerSpace: #29322f;
$martinique: #2b284e;
$alizarinCrimson: #d92b1f;
$christi: #28b711;
$athensGray: #f1f2f6;

//* VIEW COLORS
$backgroundColor: $white;
$loaderColor: $vividGreen;
$loaderColorDark: $white;
$commonPageBackgroundColor: $athensGray;

//* NAVBAR
$navbarBackgroundColor: $abbey;
$navbarLogoBackgroundColor: $white;
$navbarMenuItemBackgroundColor: $abbey;
$navbarMenuItemBorderBackgroundColor: $abbey;

$navbarMenuItemSelectedBackgroundColor: $white;
$navbarMenuItemSelectedBorderBackgroundColor: $vividGreen;
$navbarMenuItemSelectedShadowColor: $black_e3;

$navbarMenuItemTitleTextColor: $white;
$navbarMenuSelectedItemTitleTextColor: $abbey;

//* POPOVER
$popoverLabelTextColor: $white;

//* HEADER
$headerBackgroundColor: $abbey;
$headerBackgroundColorXS: $white;
$headerTitleTextColor: $white;
$headerTitleTextColorXS: $abbey; //* FOR MOBILE
$headerPreviousTitleTextColor: $white;

//* DIALOG
$dialogBackgroundColor: $white;
$dialogHeaderColor: $abbey;
$dialogTitleColor: $white;
$dialogContentTextColor: $dusk;

//* TAB PANEL VIEW
$tabPanelHeaderBackgroundColor: $white;
$tabPanelItemIndicator: $christi;
$tabPanelBottomBorderBackgroundColor: $athensGray;
$tabPanelItemTitleTextColor: $abbey;
$tabPanelItemSelectedTitleTextColor: $christi;

//* LOGIN
$loginBackgroundColor: $white;

//* VIEW COLORS
$backgroundColor: $white;

//* CustomToasts
$customToastTextSuccessColor: $lightGreen;
$customToastBackgroundSuccessColor: $lightGreen;
$customToastTextErrorColor: $darkRed;
$customToastBackgroundErrorColor: $darkRed;
$customToastTextInfoColor: $darkGrey;
$customToastBackgroundInfoColor: $darkGrey;
$customToastBodyBackgroundColor: $black_ed;
$customToastContentTextColor: $darkGrey;

//* InputText
$inputTextColor: $martinique;
$inputTextErrorColor: $alizarinCrimson;
$inputTextErrorMessageColor: $alizarinCrimson;
$inputTextStandardBackgroundColor: $athensGray;
$inputTextOutlinedBackgroundColor: $white;
$inputTextOutlinedBorderColor: $black_cc;

//* WrapperLabel
$wrapperLabelTitleColor: $black_9b;

//* Radio
$radioLabelColor: $black_1a;
$radioEffectColor: $black_1a;
$radioHoverColor: rgba($black_1a, 0.05);

//* Select
$selectInputTextColor: $abbey;
$selectBorderColor: $christi;
$selectErrorBorderColor: $darkRed;

$selectDrakModeBackgroundColor: $abbey;
$selectDrakModeBorderColor: $abbey;
$selectDarkModeInputTextColor: $white;

//* PaginatedTable
$paginatedTableHeaderTextColor: $black_ac;
$paginatedTableRowItemTextColor: $abbey;
$paginatedTableBackgroundColor: $white;
$paginatedTableHeaderBackgroundColor: $athensGray;
$paginatedTableRowBackgroundColor: $white;
$paginatedTableRowBackgroundLightColor: $white;
$paginatedTableRowBackgroundHoverColor: $black_e5;

//* Pagination
$paginationItemTextColor: $abbey;
$paginationItemSelectedBackgroundColor: $outerSpace;
$paginationItemSelectedBorderBackgroundColor: $black_d4;
$paginationItemSelectedBackgroundHoverColor: $outerSpace;
$paginationItemSelectedTextColor: $white;

//* DateRangePicker
$dateRangePickerTextColor: $black_ac;

//* DatePicker
$datePickerFieldBackgroundColor: $white;
$datePickerDayCurrentTextColor: $martinique;
$datePickerDaySelectedTextColor: $white;
$datePickerDaySelectedBackgroundColor: $christi;
$datePickerDaySelectedBackgroundHoverColor: $christi;
$datePickerInputTextColor: $abbey;

//* Search
$searchInputBackgroundColor: $black_e5;

//* ElementPicker
$elementPickerRowTitleColor: $outerSpace;
$elementPickerBorderColor: $abbey;
$elementPickerTitleColor: $outerSpace;
$elementPickerListItemBackgroundActiveColor: $black_d4;
$elementPickerListItemBackgroundColor: $athensGray;

//* UploadPicture
$uploadPicturePreviewBackgroundColor: $white;
$uploadPictureLoaderColor: $black_ac;
$uploadPictureBorderColor: $christi;

//* CommonTabFooter
$commonTabFooterBorderColor: $abbey;

//* Switch
$switchCheckedColor: $vividGreen;
$switchUncheckedColor: $abbey;
$switchThumbColor: $white;

//* ListPagesHeader
$listPagesHeaderDividerColor: $christi;

//* LineWrapper
$lineWrapperBackgroundColor: $christi;

//* ControlListItem
$controlListItemBackgroundColor: $white;
$controlListItemSiteBackgroundColor: $christi;
$controlListItemSiteColor: $white;
$controlListItemTextColor: $abbey;

//* ControlResponseItem
$controlResponseItemBorderColor: $abbey;
$controlResponseItemTextColor: $abbey;

//* EmptyList
$emptyListTextColor: $abbey;

//* ControleInformation
$controleInfoBackgroundColor: $white;
$controleInfoLineWrapperBackgroundColor: $black;
$controleInfoOptionCountTextColor: $black_9b;

//* ControleQuestions
$controleQuestionsBackgroundColor: $white;

$controleQuestionsSectionBackgroundColor: $white;
$controleQuestionsButtonAddSectionBackgroundColor: $white;
$controleQuestionsAddQuestionTitleTextColor: $black_9b;
$controleQuestionsButtonAddQuestionTitleTextColor: $white;
$controleQuestionsButtonDeleteSectionLeftLineBackgroundColor: $black;

$controleQuestionsHeaderQuestionInSectionTitleColor: $black_9b;
$controleQuestionsBorderQuestionBackgroundColor: $black_cd;

$controleResultSelectBackgroundColor: $abbey;
$controleResultSelectTextColor: $white;

//* AffaireInformations
$affaireInfosBackgroundColor: $white;

//* WeekPagination
$weekPaginationBackgroundColor: $abbey;
$weekPaginationDividerColor: $white;
$weekPaginationTextColor: $white;

//* PointageTable
$pointageTableHeaderBackgroundColor: $abbey;
$pointageTableHeaderTextColor: $white;
$pointageTableHeaderSortInfoTextColor: $black_ac;
$pointageTableHeaderTotalCellBackgroundColor: $white;
$pointageTableHeaderBorderColor: $athensGray;
$pointageTableHeaderSpacingCellBackgroundColor: $athensGray;
$pointageTableBodyBorderColor: $black_d4;
$pointageTableBodyBackgroundColor: $white;
$pointageTableBodyTextColor: $abbey;
$pointageTableBodyModifiedCellBorderColor: $black_97;
$pointageTableBodyErrorCellBorderColor: $darkRed;
$pointageTableBodyErrorCellTextColor: $darkRed;
$pointageTableBodyTitleColor: $abbey;

//* Pointage
$pointageCommentsBackgroundColor: $white;
$pointageCommentsTitleColor: $black_9b;
$pointageTooltipBackgroundColor: $black_de;
$pointageTooltipTextColor: $abbey;

//* ControleListToday
$controleListTodayDateBackgroundColor: $abbey;
$controleListTodayDateTextColor: $white;
$controleListTodayControlsBackgroundColorXS: $athensGray; //* FOR MOBILE

//* AffaireCreate
$affaireCreateBackgroudnColor: $white;

//* DualButton
$dualButtonBackgroundColor: $white;
$dualButtonTextColor: $abbey;
$dualButtonSelectedBackgroundColor: $christi;
$dualButtonSelectedTextColor: $white;

//* Compagnons
$compagnonsListHeaderLineBackgroundColor: $christi;
$compagnonListItemTextColor: $abbey;
$compagnonInformationsInterimPartBorderColor: $black_be;
$compagnonCreateBackgroudnColor: $white;
$compagnonPointageBackgroundColor: $white;
$compagnonHeureListItemBackgroundColor: $white;
$compagnonHeureListItemTextColor: $abbey;
$compagnonHeureListItemCommentTitleColor: $vividGreen;

//* Causeries
$causerieCreateBackgroundColor: $white;
$causerieFooterLineColor: $abbey;
$causerieFooterPublicationTextColor: $black_9b;
$causerieResultBackgroundColor: $white;

//* Message
$messagePageBackgroundColor: $white;
$messagePagePublishedUnpublishedTextColor: $black_9b;
$messagePageTitleColor: $abbey;

//* ItemSelectDialog
$itemSelectDialogTitle: $abbey;
$itemSelectDialogItemValueTitle: $abbey;
$itemSelectDialogBackgroundColor: $athensGray;
$itemSelectDialogSeparatorBackgroundColor: $black_97;
