@import '../../../Themes/theme.scss';

.loader-container {
    display: flex;

    .app-loader-inner {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        > div {
            background-color: $loaderColor;
        }
    }
}

.loader-container.left {
    justify-content: flex-start;
}

.loader-container.center {
    justify-content: center;
}
.loader-container.right {
    justify-content: flex-end;
}
