@import './colors.scss';
@import './fonts.scss';
@import './dimens.scss';
@import './mixins.scss';

//* MAIN
html,
body,
#root {
    margin: 0px;
    height: 100%;
}
body {
    font-family: $fontFamily;
    background-color: $backgroundColor;
}
