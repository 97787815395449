@import '../../../../Themes/theme.scss';

.dialog-layout-content {
    background-color: $dialogBackgroundColor;

    .dialog-layout-content-actions {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 56px 0px 36px 0px; //* TOP LEFT BOTTOM RIGHT

        &.xs {
            flex-direction: column;
        }

        .dialog-button {
            min-width: 120px;
            flex-direction: row;
            justify-content: center;
            margin: 0px 30px;

            &.xs {
                margin-bottom: 24px;
            }
        }
    }
}
