@import '../../../../Themes/theme.scss';

.app-async-loading-page {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    height: 100%;
    background-color: $backgroundColor;

    .content-container {
        margin: 15vh 0px;

        .app-logo {
            margin-bottom: 10vh;
        }
    }
}
