@import '../../../../Themes/theme.scss';

.base-dialog-container {
    border-radius: 0px;
    height: 100%;
    display: flex;
    flex-direction: column;

    &.withRadius {
        border-radius: 8px;
    }

    .base-dialog-header {
        height: 64px;
        background-color: $dialogHeaderColor;
        display: flex;
        align-items: center;
        .base-dialog-header-title {
            font-family: $fontFamily;
            font-size: 24px;
            padding-left: 28px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $dialogTitleColor;
        }

        .base-dialog-close-button {
            position: absolute;
            top: 0;
            right: 0;
            .close-icon {
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
    .base-dialog-body {
    }
}
