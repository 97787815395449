@import '../../../Themes/themeButton.scss';

@mixin withButtonContentStyle {
    .Button-content {
        display: flex;
        align-items: center;

        .Button-icon {
            display: flex;
            justify-content: center;
            margin-right: 16px;
        }
        .Button-title {
            text-align: center;
            justify-self: center;
            font-size: 16px;

            &.Button-search {
                font-weight: 500;
                margin-left: 29px;
                margin-right: 28px;
            }
        }

        .Button-busy {
            display: flex;
            justify-content: center;
        }
    }
}

.Button-root {
    height: 52px;
    display: flex;

    &.Button-link {
        @include withLinkButtonStyle();
        @include withButtonContentStyle();
    }

    &.Button-disabled {
        opacity: 0.5;
    }

    &.Button-standard {
        background-color: $buttonStandardBackgroundColor;
        color: $buttonStandardTextColor;
        text-transform: none;
        font-weight: bold;
        font-size: 20px;
        border-radius: 0px;

        &:hover {
            opacity: 0.8;
            background-color: $buttonStandardBackgroundColor;
        }

        .loader-inner {
            div {
                background-color: $buttonStandardLoaderColor;
            }
        }
    }

    &.Button-outlined {
        color: $buttonOutlinedColor;
        border: solid 1px $buttonOutlinedColor;
        text-transform: none;
        font-weight: bold;
        font-size: 20px;
        border-radius: 0px;

        .loader-inner {
            div {
                background-color: $buttonOutlinedLoaderColor;
            }
        }
    }

    &.Button-danger {
        color: $buttonDangerColor;
        border: solid 1px $buttonDangerColor;
        text-transform: none;
        font-weight: bold;
        font-size: 20px;
        border-radius: 0px;

        .loader-inner {
            div {
                background-color: $buttonDangerLoaderColor;
            }
        }
    }

    &.Button-withoutBorder {
        color: $buttonWithoutBorderColor;
        height: 100%;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: bold;
        border-radius: 0px;

        .loader-inner {
            div {
                background-color: $buttonWithoutBorderLoaderColor;
            }
        }
    }

    &.Button-login {
        color: $buttonWithoutBorderColor;
        border: solid 1px $buttonLoginBorderColor;
        background-color: $buttonLoginBackgroundColor;
        height: 100%;
        height: 62px;
        text-transform: none;
        border-radius: 0px;

        &:hover {
            background-color: $buttonLoginBackgroundHoverColor;
        }
        .loader-inner {
            div {
                background-color: $buttonLoginLoaderColor;
            }
        }
    }

    .MuiButton-label {
        white-space: nowrap;
        width: fit-content;

        @include withButtonContentStyle();
    }
}
